dmx.Component('f7-view', {

  attributes: {
    animate: {
      type: Boolean,
      default: true,
    },

    params: {
      type: Object,
      default: {},
    },
  },

  methods: {
    navigate (url, options) {
      if (!this._component) return;
      this._component.router.navigate(url, options);
    },

    back (url, options) {
      if (!this._component) return;
      this._component.router.back(url, options);
    },

    refresh () {
      if (!this._component) return;
      this._component.router.refreshPage();
    },

    clearHistory () {
      if (!this._component) return;
      this._component.router.clearPreviousHistory();
    }
  },

  events: {
    init: Event,
    resize: Event,
  },

  render (node) {
    dmx.f7.ready(app => {
      const view = this._component = app.views.create(node, {
        animate: this.props.animate,
        ...this.props.params,
      });

      this._registerEvents(Object.keys(this.events));

      view.on('pageInit', pageData => {
        if (!pageData.el.dmxComponent) {
          const Page = dmx.Component('f7-page');
          const page = new Page(pageData.el, this);
          page.set('route', pageData.route);

          this.children.push(page);
          this.set(page.name, page.data);

          pageData.dmxComponent = pageData.el.dmxComponent;
        } else {
          pageData.el.dmxComponent.set('route', pageData.route);
          pageData.dmxComponent = pageData.el.dmxComponent;
        }
      });

      view.on('pageReinit', pageData => {
        if (pageData.dmxComponent) {
          pageData.dmxComponent.set('route', pageData.route);
        }
      });

      view.on('pageBeforeRemove', pageData => {
        const index = this.children.findIndex(child => child.$node == pageData.el);

        if (index != -1) {
          this.del(this.children[index].name);
          this.children.splice(index, 1);
        }
      });
    });

    this.$parse();
  },

  destroy () {
    if (this._component) {
      this._component.destroy();
      delete this._component;
    }
  },

  _registerEvents: function(events) {
    for (const event of events) {
      this._component.on(event, () => this.dispatchEvent(event));
    }
  },
  
});
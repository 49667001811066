dmx.Component('f7-pie-chart', {

  attributes: {
    datasets: {
      type: Array,
      default: undefined,
    },

    datasetsValue: {
      type: String,
      default: 'value',
    },

    datasetsColor: {
      type: String,
      default: 'color',
    },

    datasetsLabel: {
      type: String,
      default: 'label',
    },

    size: {
      type: Number,
      default: 320,
    },

    tooltip: {
      type: Boolean,
      default: false,
    },

    params: {
      type: Object,
      default: {},
    },
  },

  render (node) {
    node.classList.add('pie-chart');

    dmx.f7.ready(app => {
      this._component = app.pieChart.create(this._getProps());
    });
  },

  performUpdate (updatedProps) {
    if (this._component) {
      this._component.update(this._getProps());
    }
  },

  destroy () {
    if (this._component) {
      this._component.destroy();
      delete this._component;
    }
  },

  _getProps () {
    return {
      size: this.props.size,
      datasets: this._getDatasets(),
      tooltip: this.props.tooltip,
      ...this.props.params,
      el: this.$node,
    };
  },

  _getDatasets: function() {
    return Array.isArray(this.props.datasets) ? this.props.datasets.map(data => {
      const value = dmx.parse(this.props['datasets-value'], dmx.DataScope(data));
      const color = dmx.parse(this.props['datasets-color'], dmx.DataScope(data));
      const label = dmx.parse(this.props['datasets-label'], dmx.DataScope(data));
      const dataset = { value: Number(value) };
      if (color) dataset.color = String(color);
      if (label) dataset.label = String(label);
      return dataset;
    }) : window.__WAPPLER__ ? [
      { label: 'Data 1', value: 150, color: '#4dc9f6' },
      { label: 'Data 2', value: 150, color: '#f67019' },
      { label: 'Data 3', value: 400, color: '#f53794' },
    ] : [];
  },
  
});
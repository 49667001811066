dmx.Component('f7-photo-browser', {

  initialData: {
    activeIndex: 0,
    exposed: false,
    opened: false
  },

  attributes: {
    photos: {
      type: Array,
      default: [],
    },

    exposition: {
      type: Boolean,
      default: true,
    },

    expositionHideCaptions: {
      type: Boolean,
      default: false,
    },

    swipeToClose: {
      type: Boolean,
      default: true,
    },

    popupPush: {
      type: Boolean,
      default: false,
    },

    routableModals: {
      type: Boolean,
      default: false,
    },

    url: {
      type: String,
      default: 'photos/',
    },

    type: {
      type: String,
      default: 'standalone',
      enum: ['standalone', 'popup', 'page'],
    },

    theme: {
      type: String,
      default: 'light',
      enum: ['light', 'dark'],
    },

    captionsTheme: {
      type: String,
      default: undefined, // undefined will use the same as theme
      enum: [undefined, 'light', 'dark'],
    },

    navbar: {
      type: Boolean,
      default: true,
    },

    toolbar: {
      type: Boolean,
      default: true,
    },

    pageBackLinkText: {
      type: String,
      default: 'Back',
    },

    pageCloseLinkText: {
      type: String,
      default: 'Close',
    },

    navbarShowCount: {
      type: Boolean,
      default: undefined,
    },

    navbarOfText: {
      type: String,
      default: 'of',
    },

    iconsColor: {
      type: String,
      default: undefined,
    },

    params: {
      type: Object,
      default: {},
    },
  },

  methods: {
    open (index) {
      if (!this._component) return;
      this._component.open(index);
    },

    close () {
      if (!this._component) return;
      this._component.close();
    },

    expositionToggle () {
      if (!this._component) return;
      this._component.expositionToggle();
      this.set('exposed', this._component.exposed);
    },

    expositionEnable () {
      if (!this._component) return;
      this._component.expositionEnable();
      this.set('exposed', this._component.exposed);
    },

    expositionDisable () {
      if (!this._component) return;
      this._component.expositionDisable();
      this.set('exposed', this._component.exposed);
    }
  },

  events: {
    tap: Event,
    click: Event,
    doubleTap: Event,
    doubleClick: Event,
    slideChange: Event,
    lazyImageLoad: Event,
    lazyImageReady: Event,
    open: Event,
    opened: Event,
    close: Event,
    closed: Event
  },

  render (node) {
    dmx.f7.ready(app => {
      this._createComponent();
    });

    this.$parse();
  },

  performUpdate (updatedProps) {
    if (this._component) {
      this._component.destroy();
      this._createComponent();
      this.set('activeIndex', this._component.activeIndex);
      this.set('exposed', this._component.exposed);
      this.set('opened', this._component.opened);
    }
  },

  destroy () {
    if (this._component) {
      this._component.destroy();
      delete this._component;
    }
  },

  _createComponent () {
    const photoBrowser = this._component = Framework7.instance.photoBrowser.create({
      ...this._getProps(),
      ...this.props.params,
    });

    this._registerEvents(Object.keys(this.events));

    photoBrowser.on('opened', () => {
      this.set('opened', true);
    });

    photoBrowser.on('closed', () => {
      this.set('opened', false);
    });

    photoBrowser.on('slideChange', () => {
      this.set('activeIndex', photoBrowser.activeIndex);
    })
  },

  _getProps () {
    return {
      photos: this.props.photos,
      exposition: this.props.exposition,
      expositionHideCaptions: this.props.expositionHideCaptions,
      swipeToClose: this.props.swipeToClose,
      popupPush: this.props.popupPush,
      routableModals: this.props.routableModals,
      url: this.props.url,
      type: this.props.type,
      theme: this.props.theme,
      captionsTheme: this.props.captionsTheme,
      navbar: this.props.navbar,
      toolbar: this.props.toolbar,
      pageBackLinkText: this.props.pageBackLinkText,
      popupCloseLinkText: this.props.pageCloseLinkText,
      navbarShowCount: this.props.navbarShowCount,
      navbarOfText: this.props.navbarOfText,
      iconsColor: this.props.iconsColor,
    };
  },
  
  _registerEvents (events) {
    for (const event of events) {
      this._component.on(event, () => this.dispatchEvent(event));
    }
  },

});
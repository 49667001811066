dmx.Component('f7-toggle', {

  initialData: {
    checked: false,
  },

  ui: {
    template: `<label id="@@id@@" is="dmx-f7-toggle" class="toggle">
  <input name="@@id@@" type="checkbox">
  <span class="toggle-icon"></span>
</label>`,
  },

  attributes: {
    checked: {
      type: Boolean,
      default: false,
    },

    params: {
      type: Object,
      default: {},
    },
  },

  methods: {
    toggle () {
      if (!this._component) return;
      this._component.toggle();
    },
  },

  events: {
    change: Event,
  },

  render (node) {
    dmx.f7.ready(app => {
      const toggle = this._component = app.toggle.create({
        ...this.props.params,
        el: node,
      });

      toggle.on('change', () => {
        this.set('checked', toggle.checked);
      });

      if (toggle.checked != this.props.checked) {
        toggle.toggle();
      }

      this.set('checked', toggle.checked);
    });

    this.$parse();
  },

  performUpdate (updatedProps) {
    if (this._component) {
      if (updatedProps.has('checked')) {
        if(this._component.checked != this.props.checked) {
          this._component.toggle();
        }
      }
      this.set('checked', this._component.checked);
    }
  },

  destroy (props) {
    if (this._component) {
      Framework7.instance.toggle.destroy(this._component.el);
      delete this._component;
    }
  },
});
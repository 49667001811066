dmx.Component('f7-range-dual', {

  extends: 'f7-range',

  initialData: {
    start: null,
    end: null
  },

  attributes: {
    valueStart: {
      type: Number,
      default: null,
    },

    valueEnd: {
      type: Number,
      default: null,
    },
  },

  methods: {
    setValues (start, end) {
      if (!this._component) return;
      this._component.setValue([start, end]);
    },
  },

  render (node) {
    dmx.f7.ready(app => {
      const range = this._component = app.range.create({
        dual: true,
        value: [this.props.valueStart, this.props.valueEnd],
        min: this.props.min,
        max: this.props.max,
        step: this.props.step,
        label: this.props.label,
        draggableBar: this.props.noDraggableBar ? false : this.props.draggableBar,
        vertical: this.props.vertical,
        verticalReversed: this.props.verticalReversed,
        scale: this.props.scale,
        scaleSteps: this.props.scaleSteps,
        scaleSubSteps: this.props.scaleSubSteps,
        ...this.props.params,
        el: node,
      });

      range.on('change', (range, value) => {
        this.set('start', range.value[0]);
        this.set('end', range.value[1]);
        this.dispatchEvent('change');
      });

      range.on('changed', () => this.dispatchEvent('changed'));

      this.set('start', range.value[0]);
      this.set('end', range.value[1]);
    });
  },

  performUpdate (updatedProps) {
    if (this._component) {
      if (updatedProps.has('valueStart') || updatedProps.has('valueEnd')) {
        this._component.setValue([this.props.valueStart, this.props.valueEnd]);
      }
    }
  },
  
});
dmx.Component('f7-autocomplete', {

  initialData: {
    value: null,
    opened: false,
  },

  ui: {
    template: '<input id="@@id@@" name="@@id@@" type="text" is="dmx-f7-autocomplete">',
    extendOf: ['dmx-input', 'f7-5-input'],
    replaces: 'f7-5-input',
    priority: 100
  },

  attributes: {
    limit: {
      type: Number,
      default: undefined,
    },

    preloader: {
      type: Boolean,
      default: false,
    },

    preloaderColor: {
      type: String,
      default: undefined,
    },

    value: {
      type: String,
      default: undefined,
    },

    valueProperty: {
      ui: {
        scopeField: 'data'
      },
      type: String,
      default: 'id',
    },

    textProperty: {
      ui: {
        scopeField: 'data'
      },
      type: String,
      default: 'text',
    },

    inputEvents: {
      type: String,
      default: 'input',
    },

    highlightMatches: {
      type: Boolean,
      default: true,
    },

    typeahead: {
      type: Boolean,
      default: false,
    },

    dropdownPlaceholderText: {
      type: String,
      default: undefined,
    },

    updateInputValueOnSelect: {
      type: Boolean,
      default: true,
    },

    expandInput: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Array,
      default: [],
    },

    params: {
      type: Object,
      default: {},
    },
  },

  methods: {
    open () {
      if (!this._component) return;
      this._component.open();
    },

    close: function() {
      if (!this._component) return;
      this._component.close();
    },

    preloaderShow: function() {
      if (!this._component) return;
      this._component.preloaderShow();
    },

    preloaderHide: function() {
      if (!this._component) return;
      this._component.preloaderHide();
    },
  },

  events: {
    change: Event,
    open: Event,
    opened: Event,
    close: Event,
    closed: Event,
  },

  render (node) {
    dmx.f7.ready(app => {
      const autocomplete = this._component = app.autocomplete.create({
        openIn: 'dropdown',
        limit: this.props.limit,
        preloader: this.props.preloader,
        preloaderColor: this.props.preloaderColor,
        value: Array.isArray(this.props.value) ? this.props.value : [this.props.value],
        valueProperty: this.props.valueProperty,
        textProperty: this.props.textProperty,
        inputEvents: this.props.inputEvents,
        highlightMatches: this.props.highlightMatches,
        typeahead: this.props.typeahead,
        dropdownPlaceholderText: this.props.dropdownPlaceholderText,
        updateInputValueOnSelect: this.props.updateInputValueOnSelect,
        expandInput: this.props.expandInput,
        source: this._search.bind(this),
        ...this.props.params,
        inputEl: node,
      });

      this._registerEvents(['change', 'open', 'opened', 'close', 'closed']);

      autocomplete.on('change', () => {
        this.set('value', autocomplete.value[0]);
      });

      autocomplete.on('opened', () => {
        this.set('opened', true);
      });

      autocomplete.on('closed', () => {
        this.set('opened', false);
      });
    });
  },

  destroy () {
    if (this._component) {
      this._component.destroy();
      delete this._component;
    }
  },

  _search (query, render) {
    render(query.length ? this.props.data.filter(item => {
      if (typeof item == 'object') {
        item = dmx.parse(this.props.textProperty, dmx.DataScope(item));
      }

      if (typeof item != 'string') return false;
      return item.toLowerCase().includes(query.toLowerCase());
    }) : []);
  },

  _registerEvents: function(events) {
    for (const event of events) {
      this._component.on(event, () => this.dispatchEvent(event));
    }
  },
  
});
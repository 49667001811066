dmx.Component('f7-action-sheet', {

  initialData: {
    opened: false,
  },

  attributes: {
    backdrop: {
      type: Boolean,
      default: true,
    },

    closeByBackdropClick: {
      type: Boolean,
      default: true,
    },

    closeByOutsideClick: {
      type: Boolean,
      default: false,
    },

    closeOnEscape: {
      type: Boolean,
      default: false,
    },

    animate: {
      type: Boolean,
      default: true,
    },

    grid: {
      type: Boolean,
      default: false,
    },

    convertToPopover: {
      type: Boolean,
      default: true
    },

    forceToPopover: {
      type: Boolean,
      default: false,
    },

    opened: {
      type: Boolean,
      default: false,
    },

    params: {
      type: Object,
      default: {},
    },
  },

  methods: {
    open (animate) {
      if (!this._component) return;
      this._component.open(animate);
    },

    close (animate) {
      if (!this._component) return;
      this._component.close(animate);
    }
  },

  events: {
    open: Event,
    opened: Event,
    close: Event,
    closed: Event
  },

  render (node) {
    node.classList.add('actions-modal');

    dmx.f7.ready(app => {
      const action = this._component = app.actions.create({
        backdrop: this.props.backdrop,
        closeByBackdropClick: this.props.closeByBackdropClick,
        closeByOutsideClick: this.props.closeByOutsideClick,
        closeOnEscape: this.props.closeOnEscape,
        animate: this.props.animate,
        grid: this.props.grid,
        convertToPopover: this.props.convertToPopover,
        ...this.props.params,
        el: node,
      });
      
      this._registerEvents(['open', 'opened', 'close', 'closed']);

      action.on('opened', () => {
        this.set('opened', true);
      });

      action.on('closed', () => {
        this.set('opened', false);
      });

      if (this.props.opened) {
        action.open(false);
      }
    });

    this.$parse();
  },

  performUpdate (updatedProps) {
    if (this._component) {
      if (updatedProps.has('opened')) {
        this._component[this.props.opened ? 'open' : 'close']();
      }
      this.set('opened', this._component.opened);
    }
  },

  destroy () {
    if (this._component) {
      this._component.destroy();
      delete this._component;
    }
  },

  _registerEvents (events) {
    for (const event of events) {
      this._component.on(event, () => this.dispatchEvent(event));
    }
  },
  
});
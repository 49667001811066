dmx.Component('f7-progressbar', {

  initialData: {
    progress: 0
  },

  attributes: {
    progress: {
      type: Number,
      default: null,
    },

    color: {
      type: String,
      default: 'default',
      enum: ['default', 'red', 'green', 'blue', 'pink', 'yellow', 'orange', 'purple', 'deeppurple', 'lightblue', 'teal', 'lime', 'deeporange', 'gray', 'white', 'black', 'multi'],
    },

    hidden: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    set (progress, duration) {
      if (!this._component) return;
      this._component.set(progress, duration);
    },

    show () {
      if (!this._component) return;
      this._component.show();
    },

    hide () {
      if (!this._component) return;
      this._component.hide();
    },
  },

  render  (node) {
    node.classList.add('progressbar');

    dmx.f7.ready(app => {
      this._component = {
        set: app.progressbar.set.bind(app.progressbar, node),
        show: app.progressbar.show.bind(app.progressbar, node),
        hide: app.progressbar.hide.bind(app.progressbar, node),
      };

      if (this.props.hidden) {
        this._component.hide();
      } else {
        this._component.show(this.props.progress, this.props.color);
      }
    });
  },

  performUpdate (updatedProps) {
    if (this._component) {
      if (updatedProps.has('hidden')) {
        if (this.props.hidden) {
          this._component.hide();
        } else {
          this._component.show(this.props.progress, this.props.color);
        }
      }

      if (updatedProps.has('color')) {
        const oldColor = updatedProps.get('color');

        if (oldColor) {
          this.$node.classList.remove('color-' + oldColor);
        }

        if (this.props.color != 'default') {
          this.$node.classList.add('color-' + this.props.color);
        }
      }

      if (updatedProps.has('progress')) {
        this._component.set(this.props.progress);
      }
    }
  },
  
});
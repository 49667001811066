dmx.Component('f7-sheet-modal', {

  initialData: {
    opened: false,
  },

  attributes: {
    backdrop: {
      type: Boolean,
      default: undefined, // true for MD and Aurora, false for iOS
    },

    closeByBackdropClick: {
      type: Boolean,
      default: true,
    },

    closeByOutsideClick: {
      type: Boolean,
      default: false,
    },

    closeOnEscape: {
      type: Boolean,
      default: false,
    },

    animate: {
      type: Boolean,
      default: true,
    },

    swipeToClose: {
      type: Boolean,
      default: false,
    },

    swipeToStep: {
      type: Boolean,
      default: false,
    },

    push: {
      type: Boolean,
      default: false,
    },

    opened: {
      type: Boolean,
      default: false,
    },

    params: {
      type: Object,
      default: {},
    },
  },

  methods: {
    open (animate) {
      if (!this._component) return;
      this._component.open(animate);
    },

    close (animate) {
      if (!this._component) return;
      this._component.close(animate);
    },

    stepOpen () {
      if (!this._component) return;
      this._component.stepOpen();
    },

    stepClose () {
      if (!this._component) return;
      this._component.stepClose();
    },

    stepToggle () {
      if (!this._component) return;
      this._component.stepToggle();
    },

    setSwipeSet () {
      if (!this._component) return;
      this._component.setSwipeSet();
    }
  },

  events: {
    open: Event,
    opened: Event,
    close: Event,
    closed: Event,
    stepOpen: Event,
    stepClose: Event,
    stepProgress: Event,
  },

  render (node) {
    node.classList.add('sheet-modal');

    dmx.f7.ready(app => {
      const sheet = this._component = app.sheet.create({
        backdrop: this.props.backdrop,
        closeByOutsideClick: this.props.closeByOutsideClick,
        closeOnEscape: this.props.closeOnEscape,
        animate: this.props.animate,
        swipeToClose: this.props.swipeToClose,
        swipeToStep: this.props.swipeToStep,
        push: this.props.push,
        ...this.props.params,
        el: node,
      });

      this._registerEvents(['open', 'opened', 'close', 'closed', 'stepOpen', 'stepClose', 'stepProgress']);

      sheet.on('opened', () => {
        this.set('opened', true);
      });

      sheet.on('closed', () => {
        this.set('opened', false);
      });

      if (this.props.opened) {
        sheet.open(false);
      }
    });

    this.$parse();
  },

  performUpdate (updatedProps) {
    if (this._component) {
      if (updatedProps.has('opened')) {
        this._component[this.props.opened ? 'open' : 'close']();
      }
      this.set("opened", this._component.opened);
    }
  },

  destroy () {
    if (this._component) {
      this._component.destroy();
      delete this._component;
    }
  },

  _registerEvents (events) {
    for (const event of events) {
      this._component.on(event, () => this.dispatchEvent(event));
    }
  },
  
});

dmx.Component('f7-app', {

  initialData: {
    name: 'Framework7',
    theme: 'auto',
    darkMode: false,
    initialized: false,
    device: null,
    online: false,
  },

  attributes: {
    name: {
      type: String,
      default: 'Framework7',
    },

    theme: {
      type: String,
      default: 'auto',
      enum: ['auto', 'ios', 'md'],
    },

    darkMode: {
      type: [Boolean, String],
      default: 'auto', // auto, true, false
    },

    colorTheme: {
      type: String,
      default: '#007aff',
    },

    params: {
      type: Object,
      default: {},
    },
  },

  methods: {
    alert (msg) {
      if (!this._component) return;
      this._component.dialog.alert(msg);
    },

    showPreloader (color) {
      if (!this._component) return;
      this._component.preloader.show(color);
    },

    hidePreloader () {
      if (!this._component) return;
      this._component.preloader.hide();
    },

    showProgress (color) {
      if (!this._component) return;
      this._component.progressbar.show(color);
    },

    setProgress (progress, duration) {
      if (!this._component) return;
      this._component.progressbar.set(progress, duration);
    },

    hideProgress (color) {
      if (!this._component) return;
      this._component.progressbar.hide();
    }
  },

  events: {
    init: Event,
    resize: Event,
    orientationchange: Event,
    online: Event,
    offline: Event,
  },

  render (node) {
    let theme = this.props.theme;
    let urlParams = new URLSearchParams(document.location.search);

    if (theme == 'auto' && urlParams.has('theme')) {
      theme = urlParams.get('theme');
    }

    if (this.props.darkMode != 'auto') {
      this.props.darkMode = this.props.darkMode != 'false';
    }

    const app = this._component = dmx.f7.init(node, {
      name: this.props.name,
      theme: theme,
      colors: {
        primary: this.props.colorTheme,
      },
      darkMode: this.props.darkMode,
      ...this.props.params,
    });

    this._registerEvents(['init', 'resize', 'orientationchange', 'online', 'offline']);

    app.on('connection', (isOnline) => {
      this.set('online', isOnline);
    });

    app.on('darkThemeChange', (isDarkTheme) => {
      this.set('darkTheme', isDarkTheme);
    });

    this.set({
      name: app.name,
      theme: app.theme,
      darkMode: app.darkMode,
      initialized: true,
      device: dmx.clone(app.device),
      online: app.online,
    });

    this.$parse();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('darkMode')) {
      let darkMode = this.props.darkMode;
      if (darkMode != 'auto') {
        darkMode = darkMode != 'false';
      }
      this._component.setDarkMode(darkMode);
    }

    if (updatedProps.has('colorTheme')) {
      this._component.setColorTheme(this.props.colorTheme);
    }
  },

  _registerEvents: function(events) {
    for (const event of events) {
      this._component.on(event, () => this.dispatchEvent(event));
    }
  },
  
});
dmx.Component('f7-area-chart', {

  attributes: {
    width: {
      type: Number,
      default: 640,
    },

    height: {
      type: Number,
      default: 320,
    },
    
    datasets: {
      ui: {
        multiValue: true,
        isDynamic: true,
        variables: [
          {name: 'dataSource', type: 'text', title: 'Data Source', dataBindings: true, objectKey: 'values'},
          {name: 'valueProperty', type: 'text', title: 'Value Property', dataBindings: true, scopeField: 'dataSource', objectKey: 'valueProperty', enclose: 'single'},
          {name: 'dataSetColor', type: 'css_color', title: 'Color', objectKey: 'color', enclose: 'single'},
          {name: 'dataSetLabel', type: 'text', title: 'Label', objectKey: 'label', enclose: 'single'},
        ]
      },
      type: Array,
      default: undefined,
    },

    lineChart: {
      type: Boolean,
      default: false,
    },

    axis: {
      type: Boolean,
      default: false,
    },

    axisLabels: {
      type: Array,
      default: [],
    },

    tooltip: {
      type: Boolean,
      default: false,
    },

    legend: {
      type: Boolean,
      default: false,
    },

    toggleDatasets: {
      type: Boolean,
      default: false,
    },

    maxAxisLabels: {
      type: Number,
      default: 8,
    },

    params: {
      type: Object,
      default: {},
    },
  },

  render (node) {
    node.classList.add('area-chart');

    dmx.f7.ready(app => {
      this._component = app.areaChart.create(this._getProps());
    });
  },

  performUpdate (updatedProps) {
    if (this._component) {
      this._component.update(this._getProps());
    }
  },

  destroy () {
    if (this._component) {
      this._component.destroy();
      delete this._component;
    }
  },

  _getProps () {
    return {
      width: this.props.width,
      height: this.props.height,
      datasets: this._getDatasets(),
      lineChart: this.props.lineChart,
      axis: this.props.axis,
      axisLabels: this.props.axisLabels,
      tooltip: this.props.tooltip,
      legend: this.props.legend,
      toggleDatasets: this.props.toggleDatasets,
      maxAxisLabels: this.props.maxAxisLabels,
      ...this.props.params,
      el: this.$node,
    };
  },

  _getDatasets () {
    return Array.isArray(this.props.datasets) ? this.props.datasets.map(dataset => {
      if (Array.isArray(dataset.values)) {
        if (dataset.valueProperty) {
          dataset.values = dataset.values.map(value => value[dataset.valueProperty]);
        }
        // Make sure values are numbers
        dataset.values = dataset.values.map(Number);
      } else {
        console.warn(`Dataset ${this.name}: values is not an array`);
        dataset.values = [];
      }

      return dataset;
    }) : window.__WAPPLER__ ? [ // dummy data for designview
      { label: 'Dataset 1', color: '#4dc9f6', values: [0, 100, 250, 300, 175, 400] },
      { label: 'Dataset 2', color: '#f67019', values: [100, 75, 133, 237, 40, 200] },
      { label: 'Dataset 3', color: '#f53794', values: [100, 300, 127, 40, 250, 80] },
    ] : [];
  },

});
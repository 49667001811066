dmx.Component('f7-accordion', {

  initialData: {
    opened: false,
  },

  attributes: {
    opened: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    open () {
      Framework7.instance.accordion.open(this.$node);
    },

    close () {
      Framework7.instance.accordion.close(this.$node);
    },

    toggle () {
      Framework7.instance.accordion.toggle(this.$node);
    },
  },

  events: {
    open: Event,
    opened: Event,
    close: Event,
    closed: Event,
  },

  render (node) {
    for (const event of Object.keys(this.events)) {
      node.addEventListener(`accordion:${event}`, () => {
        this.dispatchEvent(event);
      });
    }

    node.addEventListener('accordion:opened', () => {
      this.set('opened', true);
    });

    node.addEventListener('accordion:closed', () => {
      this.set('opened', false);
    });

    if (this.props.opened) {
      Framework7.instance.accordion.open(node);
    }

    this.$parse();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('opened')) {
      Framework7.instance.accordion[this.props.opened ? 'open' : 'close'](this.$node)
    }
  },
  
});
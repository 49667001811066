dmx.Actions({
  'f7.toast.show' (options) {
    Framework7.instance.toast.create({
      destroyOnClose: true,
      icon: this.parse(options.icon),
      text: this.parse(options.text),
      position: this.parse(options.position) || 'bottom',
      horizontalPosition: this.parse(options.horizontalPosition) || 'left',
      closeButton: !!this.parse(options.closeButton),
      closeButtonColor: this.parse(options.closeButtonColor),
      closeButtonText: this.parse(options.closeButtonText) || 'Ok',
      closeTimeout: this.parse(options.closeTimeout) || undefined
    }).open();
  }
});
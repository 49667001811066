dmx.Component('f7-ptr', {

  initialData: {
    refreshing: false
  },

  methods: {
    done () {
      if (!this._ptr) return;
      this._ptr.done();
    },

    refresh () {
      if (!this._ptr) return;
      this._ptr.refresh();
    },
  },

  events: {
    pullstart: Event,
    pullmove: Event,
    pullend: Event,
    refresh: Event,
    done: Event,
  },

  render (node) {
    const bottom = node.classList.contains('ptr-bottom');

    // add class if needed
    node.classList.add('ptr-content');

    if (node.children.length) {
      if (!node[(bottom ? 'last' : 'first') + 'ElementChild'].classList.contains('ptr-preloader')) {
        // add preloader html if not in dom
        node.insertAdjacentHTML(bottom ? 'beforeend' : 'afterbegin', '<div class="ptr-preloader"><div class="preloader"></div><div class="ptr-arrow"></div></div>');
        dmx.f7.ready(app => app.preloader.init(node.querySelector('.preloader')));
      }
    } else {
      node.innerHTML = '<div class="ptr-preloader"><div class="preloader"></div><div class="ptr-arrow"></div></div>';
    }

    dmx.f7.ready(app => {
      // get/create ptr instance
      this._ptr = app.ptr.get(node) || app.ptr.create(node);
      // delegate events
      this._ptr.on('pullStart', this.dispatchEvent.bind(this, 'pullstart'));
      this._ptr.on('pullMove', this.dispatchEvent.bind(this, 'pullmove'));
      this._ptr.on('pullEnd', this.dispatchEvent.bind(this, 'pullend'));
      this._ptr.on('refresh', this.dispatchEvent.bind(this, 'refresh'));
      this._ptr.on('done', this.dispatchEvent.bind(this, 'done'));
      // update data
      this._ptr.on('refresh', () => this.set('refreshing', true));
      this._ptr.on('done', () => this.set('refershing', false));
    });

    this.$parse();
  },

});
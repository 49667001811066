if (!Framework7) {
  alert('Framework7 missing!');
}

dmx.f7 = {
  events: new Framework7.Events(),
  init: function (node, params) {
    const app = new Framework7({
      ...params,
      el: node,
      routes: Array.isArray(params.routes) ? params.routes : Array.isArray(window.routes) ? window.routes : [],
    });

    if (app.initialized) {
      dmx.f7.events.emit('ready', app);
    } else {
      app.once('init', () => {
        dmx.f7.events.emit('ready', app);
      });
    }

    return app;
  },
  ready: function (handler) {
    if (!handler) return;
    if (Framework7.instance && Framework7.instance.initialized) {
      handler(Framework7.instance);
    } else {
      dmx.f7.events.once('ready', handler);
    }
  },
};
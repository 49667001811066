dmx.Component('f7-login-screen', {

  initialData: {
    opened: false,
  },

  attributes: {
    animate: {
      type: Boolean,
      default: true,
    },

    opened: {
      type: Boolean,
      default: false,
    },

    params: {
      type: Object,
      default: {},
    },
  },

  methods: {
    open (animate) {
      if (!this._component) return;
      this._component.open(animate);
    },

    close (animate) {
      if (!this._component) return;
      this._component.close(animate);
    },
  },

  events: {
    open: Event,
    opened: Event,
    close: Event,
    closed: Event
  },

  render (node) {
    node.classList.add('login-screen');

    dmx.f7.ready(app => {
      const loginScreen = this._component = app.loginScreen.create({
        animate: this.props.animate,
        ...this.props.params,
        el: node,
      });

      this._registerEvents(['open', 'opened', 'close', 'closed']);

      loginScreen.on('opened', () => {
        this.set('opened', true);
      });

      loginScreen.on('closed', () => {
        this.set('opened', false);
      });

      if (this.props.opened) {
        loginScreen.open(false);
      }
    });

    this.$parse();
  },

  performUpdate (updatedProps) {
    if (this._component) {
      if (updatedProps.has('opened')) {
        this._component[this.props.opened ? 'open' : 'close']();
      }
      this.set('opened', this._component.opened);
    }
  },

  destroy () {
    if (this._component) {
      this._component.destroy();
      delete this._component;
    }
  },

  _registerEvents (events) {
    for (const event of events) {
      this._component.on(event, () => this.dispatchEvent(event));
    }
  },
  
});
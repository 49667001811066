dmx.Actions({
  'f7.dialog.alert' (options) {
    const text = this.parse(options.text);
    const title = this.parse(options.title);

    return new Promise(resolve => {
      Framework7.instance.dialog.alert(text, title, resolve);
    });
  },

  'f7.dialog.confirm' (options) {
    const text = this.parse(options.text);
    const title = this.parse(options.title);

    return new Promise(resolve => {
      Framework7.instance.dialog.confirm(
        text,
        title,
        () => {
          if (options.then) {
            this._exec(options.then).then(() => resolve(true));
          } else {
            resolve(true);
          }
        },
        () => {
          if (options.else) {
            this._exec(options.else).then(() => resolve(false));
          } else {
            resolve(false);
          }
        }
      );
    });
  },

  'f7.dialog.prompt' (options) {
    const text = this.parse(options.text);
    const title = this.parse(options.title);
    const defaultValue = this.parse(options.defaultValue);

    return new Promise(resolve => {
      Framework7.instance.dialog.prompt(
        text,
        title,
        (value) => resolve(value),
        () => resolve(null),
        defaultValue
      );
    });
  },

  'f7.dialog.login' (options) {
    const text = this.parse(options.text);
    const title = this.parse(options.title);

    return new Promise(resolve => {
      Framework7.instance.dialog.login(
        text,
        title,
        (username, password) => resolve({ username, password }),
        () => resolve(null)
      );
    });
  },

  'f7.dialog.password' (options) {
    const text = this.parse(options.text);
    const title = this.parse(options.title);

    return new Promise(resolve => {
      Framework7.instance.dialog.password(
        text,
        title,
        (password) => resolve(password),
        () => resolve(null)
      );
    });
  },

  'f7.dialog.preloader' (options) {
    const title = this.parse(options.title);
    const color = this.parse(options.color);

    Framework7.instance.dialog.preloader(title, color);
  },

  'f7.dialog.close' (options) {
    Framework7.instance.dialog.close();
  },
});

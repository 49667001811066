dmx.Component('f7-messages', {

  attributes: {
    newMessagesFirst: {
      type: Boolean,
      default: false
    },

    messages: {
      type: Array,
      default: []
    }
  },

  methods: {
    showTyping (message) {
      if (!this._component) return;
      this._component.showTyping(message);
    },

    hideTyping () {
      if (!this._component) return;
      this._component.hideTyping();
    },

    addMessage (message, method, animate) {
      if (!this._component) return;
      this._component.addMessage(message, method, animate);
    },

    addMessages (messages, method, animate) {
      if (!this._component) return;
      this._component.addMessages(messages, method, animate);
    },

    removeMessage (message) {
      if (!this._component) return;
      this._component.removeMessage(message);
    },

    removeMessages (messages) {
      if (!this._component) return;
      this._component.removeMessages(messages);
    },

    scroll (duration, position) {
      if (!this._component) return;
      this._component.scroll(duration, position);
    },

    renderMessages () {
      if (!this._component) return;
      this._component.renderMessages();
    },

    layout () {
      if (!this._component) return;
      this._component.layout();
    },

    clear () {
      if (!this._component) return;
      this._component.clear();
    }
  },

  render (node) {
    dmx.f7.ready(app => {
      this._component = Framework7.instance.messages.create({
        ...this.props,
        el: node,
      });
    });

    this.$parse();
  },

  performUpdate (props) {
    if (this._component) {
      if (props.has('messages')) {
        this._component.messages = this.props.messages;
      }
      this._component.renderMessages();
    }
  },

  destroy: function () {
    if (this._component) {
      this._component.destroy();
      this._component = undefined;
    }
  },

});
dmx.Actions({
  'f7.progressbar.show' (options) {
    Framework7.instance.progressbar.show(this.parse(options.color));
  },
  
  'f7.progressbar.set' (options) {
    Framework7.instance.progressbar.set(this.parse(options.progress), this.parse(options.duration));
  },
  
  'f7.progressbar.hide' (options) {
    Framework7.instance.progressbar.hide();
  },
});
dmx.Component('f7-gauge', {

  attributes: {
    type: {
      type: String,
      default: 'circle',
      enum: ['circle', 'semicircle'],
    },

    value: {
      type: Number,
      default: 0,
    },

    size: {
      type: Number,
      default: 200,
    },

    bgColor: {
      type: String,
      default: 'transparent',
    },

    borderBgColor: {
      type: String,
      default: '#eeeeee',
    },

    borderColor: {
      type: String,
      default: '#000000',
    },

    borderWidth: {
      type: String,
      default: '10',
    },

    valueText: {
      type: String,
      default: null,
    },

    valueTextColor: {
      type: String,
      default: '#000000',
    },

    valueFontSize: {
      type: String,
      default: '31',
    },

    valueFontWeight: {
      type: String,
      default: '500',
    },

    labelText: {
      type: String,
      default: null,
    },
      
    labelTextColor: {
      type: String,
      default: '#888888',
    },

    labelFontSize: {
      type: String,
      default: '14',
    },

    labelFontWeight: {
      type: String,
      default: '400',
    },

    params: {
      type: Object,
      default: {},
    },
  },

  render (node) {
    node.classList.add('gauge');

    dmx.f7.ready(app => {
      const gauge = this._component = app.gauge.create({
        ...this._getProps(),
        ...this.props.params,
        el: node,
      });
    });
  },

  performUpdate (updatedProps) {
    if (this._component) {
      this._component.update(this._getProps());
    }
  },

  destroy () {
    if (this._component) {
      this._component.destroy();
      delete this._component;
    }
  },

  _getProps () {
    return {
      type: this.props.type,
      value: this.props.value,
      size: this.props.size,
      bgColor: this.props.bgColor,
      borderBgColor: this.props.borderBgColor,
      borderColor: this.props.borderColor,
      borderWidth: this.props.borderWidth,
      valueText: this.props.valueText,
      valueTextColor: this.props.valueTextColor,
      valueFontSize: this.props.valueFontSize,
      valueFontWeight: this.props.valueFontWeight,
      labelText: this.props.labelText,
      labelTextColor: this.props.labelTextColor,
      labelFontSize: this.props.labelFontSize,
      labelFontWeight: this.props.labelFontWeight,
    };
  },

});
dmx.Component('f7-calendar', {

  initialData: {
    value: [],
    opened: false,
  },

  ui: {
    template: '<input id="@@id@@" name="@@id@@" type="text" is="dmx-f7-calendar">',
    extendOf: ['dmx-input', 'f7-5-input'],
    replaces: 'f7-5-input',
    priority: 100
  },

  attributes: {
    locale: {
      type: String,
      default: undefined, // will use browser locale
    },

    value: {
      type: Array,
      default: undefined,
    },

    firstDay: {
      type: Number,
      default: 1,
    },

    weekendDays: {
      type: Array,
      default: [0, 6],
    },

    // dateFormat?

    multiple: {
      type: Boolean,
      default: false,
    },

    rangePicker: { // not compatible with multiple
      type: Boolean,
      default: false,
    },

    rangePickerMinDays: {
      type: Number,
      default: 1,
    },

    rangePickerMaxDays: {
      type: Number,
      default: 0, // 0 means no maximum
    },

    direction: {
      type: String,
      default: 'horizontal',
      enum: ['horizontal', 'vertical'],
      validate: value => ['horizontal', 'vertical'].includes(value),
    },

    minDate: {
      type: Date,
      default: null,
    },

    maxDate: {
      type: Date,
      default: null,
    },

    touchMove: {
      type: Boolean,
      default: true,
    },

    animate: {
      type: Boolean,
      default: true,
    },

    closeOnSelect: {
      type: Boolean,
      default: false,
    },

    weekHeader: {
      type: Boolean,
      default: true,
    },

    monthSelector: {
      type: Boolean,
      default: true,
    },

    monthPicker: {
      type: Boolean,
      default: true,
    },

    yearSelector: {
      type: Boolean,
      default: true,
    },

    yearPicker: {
      type: Boolean,
      default: true,
    },

    yearPickerMin: {
      type: Number,
      default: undefined,
    },

    yearPickerMax: {
      type: Number,
      default: undefined,
    },

    timePicker: {
      type: Boolean,
      default: false,
    },

    // timePickerFormat?

    timePickerPlaceholder: {
      type: String,
      default: 'Select time',
    },

    // Container/opener-specific parameters

    openIn: {
      type: String,
      default: 'auto',
      enum: ['auto', 'popover', 'sheet', 'customModal'],
      validate: value => ['auto', 'popover', 'sheet', 'customModal'].includes(value),
    },

    sheetPush: {
      type: Boolean,
      default: false,
    },

    // sheetSwipeToClose?

    scrollToInput: {
      type: Boolean,
      default: true,
    },

    inputReadOnly: {
      type: Boolean,
      default: true,
    },

    closeByOutsideClick: {
      type: Boolean,
      default: true,
    },

    toolbar: {
      type: Boolean,
      default: true,
    },

    toolbarCloseText: {
      type: String,
      default: 'Done',
    },

    header: {
      type: Boolean,
      default: false,
    },

    headerPlaceholder: {
      type: String,
      default: 'Select date',
    },

    // backdrop?

    closeByBackdropClick: {
      type: Boolean,
      default: true,
    },

    params: {
      type: Object,
      default: {},
    },
  },

  methods: {
    setValue (value) {
      this._component.setValue(value);
    },
    addValue (value) {
      this._component.addValue(value);
    },
    nextMonth (duration) {
      this._component.nextMonth(duration);
    },
    prevMonth (duration) {
      this._component.prevMonth(duration);
    },
    nextYear () {
      this._component.nextYear();
    },
    prevYear () {
      this._component.prevYear();
    },
    setYearMonth (year, month, duration) {
      this._component.setYearMonth(year, month, duration);
    },
    open () {
      this._component.open();
    },
    close () {
      this._component.close();
    },
  },

  events: {
    dayclick: Event,
    change: Event,
    open: Event,
    opened: Event,
    close: Event,
    closed: Event,
  },

  render (node) {
    dmx.f7.ready(app => {
      const calendar = this._component = app.calendar.create({
        ...this._getProps(),
        ...this.props.params,
        inputEl: node,
      });

      this._registerEvents(['change', 'open', 'opened', 'close', 'closed']);

      calendar.on('change', () => {
        this.set('value', calendar.value.map(date => date.toISOString()));
      });

      calendar.on('opened', () => {
        this.set('opened', true);
      });

      calendar.on('closed', () => {
        this.set('opened', false);
      });
    });
  },

  performUpdate: function(updatedProps) {
    if (this._component) {
      this._component.update(this._getProps());
    }
  },

  destroy: function() {
    if (this._component) {
      this._component.destroy();
      delete this._component;
    }
  },

  _getProps: function() {
    return {
      locale: this.props.locale,
      value: this.props.value,
      firstDay: this.props.firstDay,
      weekendDays: this.props.weekendDays,
      multiple: this.props.multiple,
      rangePicker: this.props.rangePicker,
      rangePickerMinDays: this.props.rangePickerMinDays,
      rangePickerMaxDays: this.props.rangePickerMaxDays,
      direction: this.props.direction,
      minDate: this._toDate('minDate'),
      maxDate: this._toDate('maxDate'),
      touchMove: this.props.touchMove,
      animate: this.props.animate,
      closeOnSelect: this.props.closeOnSelect,
      weekHeader: this.props.weekHeader,
      monthSelector: this.props.monthSelector,
      monthPicker: this.props.monthPicker,
      yearSelector: this.props.yearSelector,
      yearPicker: this.props.yearPicker,
      yearPickerMin: this.props.yearPickerMin,
      yearPickerMax: this.props.yearPickerMax,
      timePicker: this.props.timePicker,
      timePickerPlaceholder: this.props.timePickerPlaceholder,
      openIn: this.props.openIn,
      sheetPush: this.props.sheetPush,
      scrollToInput: this.props.scrollToInput,
      inputReadOnly: this.props.inputReadOnly,
      closeByOutsideClick: this.props.closeByOutsideClick,
      toolbar: this.props.toolbar,
      toolbarCloseText: this.props.toolbarCloseText,
      header: this.props.header,
      headerPlaceholder: this.props.headerPlaceholder,
      closeByBackdropClick: this.props.closeByBackdropClick,
    };
  },

  _registerEvents: function(events) {
    for (const event of events) {
      this._component.on(event, () => this.dispatchEvent(event));
    }
  },

  _toDate: function(name, props) {
    const value = props ? props[name] : this.props[name];
    
    let date = null;
    if (value) {
      date = new Date(value);
      if (date.toString() == 'Invalid Date') {
        date = null;
      }
    }

    return date;
  },

});
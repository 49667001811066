dmx.Component('f7-picker', {

  initialData: {
    value: [],
    opened: false,
  },

  ui: {
    template: '<input id="@@id@@" name="@@id@@" type="text" is="dmx-f7-picker">',
    extendOf: ['dmx-input', 'f7-5-input'],
    replaces: 'f7-5-input',
    priority: 100
  },

  attributes: {
    rotateEffect: {
      type: Boolean,
      default: false,
    },

    momentumRatio: {
      type: Number,
      default: 7,
    },

    mousewheel: {
      type: Boolean,
      default: true,
    },

    updateValuesOnMomentum: {
      type: Boolean,
      default: false,
    },

    updateValuesOnTouchmove: {
      type: Boolean,
      default: true,
    },

    updateValuesOnMousewheel: {
      type: Boolean,
      default: true,
    },

    freeMode: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Array,
      default: undefined,
    },
    
    cols: {
      ui: {
        multiValue: true,
        isDynamic: true,
        variables: [
          {name: 'textAlign', type: 'droplist', title: 'Text Align', values: [{value:'left',title:'Left'},{value:'center',title:'Center'},{value:'right',title:'Right'}], objectKey: 'textAlign', enclose: 'single'},
          {name: 'dataSource', type: 'text', title: 'Data Source', dataBindings: true, objectKey: 'source'},
          {name: 'valueProperty', type: 'text', title: 'Value Property', dataBindings: true, scopeField: 'dataSource', objectKey: 'valueProperty', enclose: 'single'},
          {name: 'displayProperty', type: 'text', title: 'Display Property', dataBindings: true, scopeField: 'dataSource', objectKey: 'displayProperty', enclose: 'single'},
        ]
      },
      type: Array,
      default: [],
    },

    // Container/opener-specific parameters

    openIn: {
      type: String,
      default: 'auto',
      enum: ['auto', 'popover', 'sheet'],
      validate: value => ['auto', 'popover', 'sheet'].includes(value),
    },

    backdrop: {
      type: Boolean,
      default: undefined,
    },

    sheetPush: {
      type: Boolean,
      default: false,
    },

    sheetSwipeToClose: {
      type: Boolean,
      default: false,
    },

    scrollToInput: {
      type: Boolean,
      default: true,
    },

    inputReadOnly: {
      type: Boolean,
      default: true,
    },

    closeByOutsideClick: {
      type: Boolean,
      default: true,
    },

    toolbar: {
      type: Boolean,
      default: true,
    },

    toolbarCloseText: {
      type: String,
      default: 'Done',
    },

    routableModals: {
      type: Boolean,
      default: true,
    },

    url: {
      type: String,
      default: 'select/',
    },

    opened: {
      type: Boolean,
      default: false,
    },
    
    params: {
      type: Object,
      default: {},
    },
  },

  methods: {
    open () {
      if (!this._component) return;
      this._component.open();
    },

    close () {
      if (!this._component) return;
      this._component.close();
    },
  },

  events: {
    change: Event,
    init: Event,
    open: Event,
    opened: Event,
    close: Event,
    closed: Event,
  },

  render (node) {
    dmx.f7.ready(app => {
      this._createComponent();
    });
  },

  performUpdate (updatedProps) {
    if (this._component) {
      if (updatedProps.has('opened')) {
        this._component[this.props.opened ? 'open' : 'close']();
      }

      if (updatedProps.has('cols')) {
        this._component.destroy();
        this._createComponent();
      }
    }
  },

  destroy () {
    if (this._component) {
      this._component.destroy();
      delete this._component;
    }
  },

  _createComponent () {
    const picker = this._component = Framework7.instance.picker.create(this._getParams());

    this._registerEvents(Object.keys(this.events));

    picker.on('init', () => {
      this.set('value', picker.value);
    });

    picker.on('change', () => {
      this.set('value', picker.value);
    });

    picker.on('opened', () => {
      this.set('opened', true);
    });

    picker.on('closed', () => {
      this.set('closed', false);
    });

    if (this.props.opened) {
      picker.open();
    }
  },

  _getParams () {
    return {
      rotateEffect: this.props.rotateEffect,
      momentumRatio: this.props.momentumRatio,
      mousewheel: this.props.mousewheel,
      updateValuesOnMomentum: this.props.updateValuesOnMomentum,
      updateValuesOnTouchmove: this.props.updateValuesOnTouchmove,
      updateValuesOnMousewheel: this.props.updateValuesOnMousewheel,
      freeMode: this.props.freeMode,
      value: this.props.value,
      cols: this._getCols(),
      openIn: this.props.openIn,
      backdrop: this.props.backdrop ? this.props.backdrop : undefined,
      sheetPush: this.props.sheetPush,
      sheetSwipeToClose: this.props.sheetSwipeToClose,
      scrollToInput: this.props.scrollToInput,
      inputReadOnly	: this.props.inputReadOnly,
      closeByOutsideClick: this.props.closeByOutsideClick,
      toolbar: this.props.toolbar,
      toolbarCloseText: this.props.toolbarCloseText,
      routableModals: this.props.routableModals,
      url: this.props.url,
      ...this.props.params,
      inputEl: this.$node,
    };
  },

  _getCols () {
    return Array.isArray(this.props.cols) ? this.props.cols.map((col, i) => {
      if (Array.isArray(col.source)) {
        col.values = col.source.slice(0);
        
        if (col.valueProperty) {
          col.values = col.source.map(item => item[col.valueProperty]);
        }

        if (col.displayProperty) {
          col.displayValues = col.source.map(item => item[col.displayProperty]);
        }
      }
      
      if (!Array.isArray(col.values)) {
        col.values = [];
        console.warn(`Picker ${this.name}: Column ${i+1} has no values`);
      }

      return dmx.clone(col);
    }) : [];
  },

  _registerEvents (events) {
    for (const event of events) {
      this._component.on(event, () => this.dispatchEvent(event));
    }
  },
  
});
dmx.Component('f7-notification', {

  initialData: {
    opened: false,
  },

  attributes: {
    icon: {
      type: String,
      default: undefined,
    },

    title: {
      type: String,
      default: undefined,
    },

    titleRightText: {
      type: String,
      default: undefined,
    },

    subtitle: {
      type: String,
      default: undefined,
    },

    text: {
      type: String,
      default: undefined,
    },

    closeButton: {
      type: Boolean,
      default: false,
    },

    closeTimeout: {
      type: Number,
      default: undefined,
    },

    closeOnClick: {
      type: Boolean,
      default: false,
    },

    swipeToClose: {
      type: Boolean,
      default: true,
    },

    cssClass: {
      type: String,
      default: undefined,
    },

    opened: {
      type: Boolean,
      default: false,
    },

    params: {
      type: Object,
      default: {},
    },
  },

  methods: {
    open () {
      if (!this._component) return;
      this._component.open();
    },

    close () {
      if (!this._component) return;
      this._component.close();
    },
  },

  events: {
    open: Event,
    opened: Event,
    close: Event,
    closed: Event,
    click: Event,
  },

  render (node) {
    dmx.f7.ready(app => {
      const notification = this._component = app.notification.create(this._getProps());

      this._registerEvents(['open', 'opened', 'close', 'closed', 'click']);

      notification.on('opened', () => {
        this.set('opened', true);
      });

      notification.on('closed', () => {
        this.set('opened', false);
      });

      if (this.props.opened) {
        notification.open();
      }
    });

    this.$parse();
  },

  performUpdate (updatedProps) {
    if (this._component) {
      if (updatedProps.has('opened')) {
        this._component[this.props.opened ? 'open' : 'close']();
      }
      this.set('opened', this._component.opened);
    }
  },

  destroy () {
    if (this._component) {
      Framework7.instance.notification.destroy(this._component.el);
      delete this._component;
    }
  },

  _getProps () {
    return {
      icon: this.props.icon,
      title: this.props.title,
      titleRightText: this.props.titleRightText,
      subtitle: this.props.subtitle,
      text: this.props.text,
      closeButton: this.props.closeButton,
      closeTimeout: this.props.closeTimeout,
      closeOnClick: this.props.closeOnClick,
      swipeToClose: this.props.swipeToClose,
      cssClass: this.props.cssClass,
      ...this.props.params,
    };
  },

  _registerEvents: function(events) {
    for (const event of events) {
      this._component.on(event, () => this.dispatchEvent(event));
    }
  },
  
});